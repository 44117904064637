import React from 'react';
import GridItem from 'components/core/Grid/GridItem';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
// core components
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid/Grid';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/core/CustomButtons/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CardIcon from 'components/core/Card/CardIcon';
import { request, URLs } from '../../request';
import { withRouter } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Switch from 'react-switch';
// icons
import SearchIcon from '@material-ui/icons/Search';
import BlockIcon from '@material-ui/icons/Block';
import HistoryIcon from '@material-ui/icons/History';
import EditIcon from '@material-ui/icons/Edit';
// style
import BlockListStyle from 'assets/jss/views/BlockListStyle';
// constants
import { BLOCK_HISTORY_ACTIONS } from '../../constants';
import Swal from 'sweetalert2';

class BlockList extends React.Component {
  state = {
    loading: true,
    users: [],
    blockedUsers: [],
    blockReasons: [],
    isCreateOpen: false,
    isEditOpen: false,
    userHistory: [],
    blockReason: {},
    idUser: '',
    blockNote: '',
    isUserBlocked: '',
    autoUnblockMonths: 0,
  };

  async componentDidMount() {
    const promises = [];

    this.fetchData();

    promises.push(
      request.GET(URLs.backoffice.BLOCKING_REASONS, { trashed: false, active: '1' }).then(res => {
        this.setState({
          blockReasons: res.data,
        });
      }),
    );

    request.GET(URLs.backoffice.USERS).then(respond => {
      this.setState({
        users: respond.data.users,
      });
    });

    await Promise.all(promises);
    this.setState({ loading: false });
  }

  fetchData = () => {
    const { filterActive, from, to, filterReasonId, filterUserId, filterAgentId } = this.state;
    // eslint-disable-next-line no-nested-ternary
    const active = filterActive ? (filterActive === '1' ? 'true' : 'false') : '';
    return request
      .GET(URLs.backoffice.BLOCKED_USERS, {
        active,
        reason_id: filterReasonId,
        id_number: filterUserId,
        agent_id: filterAgentId,
        from,
        to,
      })
      .then(respond => {
        this.setState({
          // data: respond.data,
          blockedUsers: respond.data.data,
        });
      });
  };

  /* filters */

  handleIdFilter = e => this.setState({ filterUserId: e.target.value });

  handleAgentFilter = e => this.setState({ filterAgentId: e.target.value });

  handleReasonFilter = e => this.setState({ filterReasonId: e.target.value });

  handleStateFilter = e => this.setState({ filterActive: e.target.value });

  handleFromDateChange = e => this.setState({ from: e.target.value }, () => this.fetchData());

  handleToDateChange = e => this.setState({ to: e.target.value }, () => this.fetchData());

  handleSearchSubmit = () => this.fetchData();

  /* functions */
  handleBlockNewUser = user => {
    this.setState({
      idUser: '',
      blockNote: '',
      isCreateOpen: true,
      blockReason: user.blocking_reasons || {},
    });
  };

  handleEditOpen = user => {
    this.setState({
      isEditOpen: true,
      isUserBlocked: user.active === 1,
      blockReason: user.blocking_reasons || {},
      blockNote: user.notes,
      userId: user.id,
      user,
    });
    this.setState({ autoUnblockMonths: user.blocking_reasons.auto_unblock_months });
  };

  handleBlockReasonChange = e => {
    const blockReasonId = e.target.value;
    this.setAutoUnblockMonths(blockReasonId);
    this.setState(prev => ({ blockReason: prev.blockReasons.find(r => r.id === blockReasonId) }));
  };

  setAutoUnblockMonths = blockReasonId => {
    this.state.blockReasons.map(el => {
      if (el.id === blockReasonId) {
        this.setState({ autoUnblockMonths: el.auto_unblock_months });
      }
    });
  };

  handleBlockIdChange = e => this.setState({ idUser: e.target.value });

  handleBlockNoteChange = e => this.setState({ blockNote: e.target.value });

  handleBlockStateChange = val => {
    this.setState({ isUserBlocked: val });
  };

  handleCreateBlockedUser = () => {
    const body = {
      blocking_reason_id: this.state.blockReason.id,
      id_number: this.state.idUser,
      notes: this.state.blockNote,
    };

    // eslint-disable-next-line max-len
    request
      .POST(URLs.backoffice.BLOCKED_USERS, { ...body })
      .then(() => {
        this.updateUI();
        this.handleCreateClose();
        this.fetchData();
      })
      .catch(res => {
        this.handleCreateClose();
        if (res.response.data && res.response.data.message) {
          const message = res.response.data.errors.length
            ? JSON.stringify(res.response.data.errors)
            : res.response.data.message;
          Swal.fire({
            title: 'عذرا',
            text: message,
            type: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'حسنا',
          });
        }
      });
  };

  handleUpdateBlockedUser = () => {
    const body = {
      id_number: this.state.user.id_number,
      blocking_reason_id: this.state.blockReason.id,
      notes: this.state.blockNote,
      active: this.state.isUserBlocked,
    };
    // eslint-disable-next-line max-len
    request
      .PUT(URLs.backoffice.BLOCKED_USERS, { user_id: this.state.userId, ...body })
      .then(() => {
        this.updateUI();
        this.handleEditClose();
      })
      .catch(res => {
        this.handleEditClose();
        if (res.response.data && res.response.data.message) {
          Swal.fire({
            title: 'عذرا',
            text: res.response.data.message,
            type: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'حسنا',
          });
        }
      });
  };

  handleCreateClose = () => this.setState({ isCreateOpen: false });

  handleEditClose = () => this.setState({ isEditOpen: false });

  handleHistoryOpen = user => {
    this.setState({ modalLoading: true, isHistoryOpen: true });
    request.GET(URLs.backoffice.BLOCK_HISTORY, { user_id: user.id }).then(res => {
      this.setState({
        user,
        userHistory: res.data,
        modalLoading: false,
      });
    });
  };

  handleHistoryClose = () =>
    this.setState({
      isHistoryOpen: false,
      userHistory: [],
    });

  updateUI = () => {
    this.setState(prev => {
      const { userId, blockReason, isUserBlocked, blockNote } = this.state;
      const updated = prev.blockedUsers.map(el => {
        const user = el;
        if (user.id === userId) {
          if (blockReason) {
            user.blocking_reasons = {};
            user.blocking_reasons.id = blockReason.id || user.blocking_reasons.id;
            user.blocking_reasons.reason = blockReason.reason || user.blocking_reasons.reason;
          }
          user.notes = blockNote;
          user.active = isUserBlocked ? 1 : 0;
        }
        return user;
      });
      return { blockedUsers: updated };
    });
  };

  render() {
    const { classes } = this.props;
    const {
      blockReasons,
      isUserBlocked,
      blockReason,
      idUser,
      blockNote,
      userHistory,
      modalLoading,
      blockedUsers,
      autoUnblockMonths,
    } = this.state;
    return (
      <div>
        <Grid container>
          <GridItem xs={12} sm={12} md={12}>
            <Grid container>
              <GridItem xs={3} sm={3} md={3}>
                <h5 className={classes.filterLabel}>رقم هوية العميل</h5>
                <TextField
                  style={{ marginTop: '0px' }}
                  id="idNumber"
                  type="text"
                  fullWidth
                  margin="normal"
                  onChange={this.handleIdFilter}
                  value={this.state.idNumber}
                />
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <h5 className={classes.filterLabel}>بواسطة</h5>
                <Select
                  style={{ display: 'flex', flex: 1 }}
                  onChange={this.handleAgentFilter}
                  value={this.state.filterAgentId}
                >
                  <MenuItem value="">None</MenuItem>
                  {this.state.users.map($val => (
                    <MenuItem value={$val.id}>{$val.name}</MenuItem>
                  ))}
                  <MenuItem value="null">النظام</MenuItem>
                </Select>
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <h5 className={classes.filterLabel}>سبب الحظر</h5>
                <Select
                  style={{ display: 'flex', flex: 1 }}
                  onChange={this.handleReasonFilter}
                  value={this.state.filterReasonId}
                >
                  <MenuItem value="">None</MenuItem>
                  {this.state.blockReasons.map($val => (
                    <MenuItem value={$val.id}>{$val.reason}</MenuItem>
                  ))}
                </Select>
              </GridItem>

              <GridItem xs={2} sm={2} md={2}>
                <h5 className={classes.filterLabel}>حالة</h5>
                <Select
                  style={{ display: 'flex', flex: 1 }}
                  onChange={this.handleStateFilter}
                  value={this.state.filterActive}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="1">محظور</MenuItem>
                  <MenuItem value="0">غير محظور</MenuItem>
                </Select>
              </GridItem>

              <GridItem xs={2} sm={2} md={2} style={{ marginTop: '25px' }}>
                <Button color="primary" onClick={this.handleSearchSubmit} justIcon>
                  <SearchIcon />
                </Button>
              </GridItem>
            </Grid>
            <Card>
              <CardHeader icon title="قائمة الحظر" subtitle="تجد هنا قائمة المستخدمين المحظورين">
                <CardIcon color="info" isTableIcon>
                  <BlockIcon />
                </CardIcon>
                <div style={{ display: 'inline-block', float: 'left' }}>
                  <Button
                    color="info"
                    style={{ marginTop: 20, fontWeight: 700 }}
                    onClick={this.handleBlockNewUser}
                    link
                  >
                    إضافة حظر لمستخدم
                  </Button>
                </div>
                <div style={{ float: 'left', display: 'flex', marginLeft: 25 }}>
                  <div style={{ marginLeft: 20 }}>
                    <h6 className={classes.filterLabel}>تاريخ البداية</h6>
                    <TextField
                      style={{ margin: 'inline-block', fontSize: 12 }}
                      id="date"
                      type="date"
                      onChange={this.handleFromDateChange}
                      inputProps={{ style: { fontSize: 12, color: 'grey' } }}
                      value={this.state.from}
                    />
                  </div>
                  <div>
                    <h6 className={classes.filterLabel}>تاريخ النهاية</h6>
                    <TextField
                      style={{ display: 'inline-block' }}
                      id="date"
                      type="date"
                      onChange={this.handleToDateChange}
                      inputProps={{ style: { fontSize: 12, color: 'grey' } }}
                      value={this.state.to}
                    />
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <Grid container justify="center">
                  <React.Fragment>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>رقم الهوية</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>تاريخ الحظر</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>تاريخ انتهاء الحظر</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>الحالة</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>سبب الحظر </TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {this.state.loading ? (
                        <CircularProgress style={{ alignSelf: 'center' }} />
                      ) : !blockedUsers.length ? (
                        <TableCell>لا يوجد</TableCell>
                      ) : (
                        <TableBody>
                          {blockedUsers.map(el => (
                            <TableRow>
                              <TableCell>{el.id_number}</TableCell>
                              <TableCell>{el.created_at.substring(0, 10)}</TableCell>
                              <TableCell>
                                {el.auto_unblock_at ? (
                                  <span style={{ color: el.active === 1 ? '#1F2DA9' : 'green' }}>
                                    {el.auto_unblock_at.substring(0, 10)}
                                  </span>
                                ) : (
                                  <span style={{ color: 'red' }}> مفتوح</span>
                                )}
                              </TableCell>

                              <TableCell>
                                {el.active === 1 ? (
                                  <span style={{ color: 'red' }}>محظور </span>
                                ) : (
                                  <span style={{ color: 'green' }}> غير محظور</span>
                                )}
                              </TableCell>
                              <TableCell>
                                {el.blocking_reasons ? el.blocking_reasons.reason : '-'}
                              </TableCell>
                              <TableCell className={classes.actionsCell}>
                                <Button link onClick={() => this.handleEditOpen(el)}>
                                  <EditIcon color="secondary" />
                                </Button>
                              </TableCell>
                              <TableCell className={classes.actionsCell}>
                                <Button onClick={() => this.handleHistoryOpen(el)} link>
                                  <HistoryIcon color="primary" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </React.Fragment>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
        <Modal
          className={classes.modal}
          open={this.state.isEditOpen}
          onClose={this.handleEditClose}
        >
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.mTitle}>تعديل الحظر</p>
            </div>
            <div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="newReason" className={classes.mLabel}>
                    سبب الحظر
                  </label>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Select
                    disabled
                    value={blockReason.id}
                    input={<OutlinedInput labelWidth={0} name="type" />}
                    className={classes.mSelect}
                  >
                    {blockReasons.map(item => (
                      <MenuItem id={item.id} value={item.id}>
                        {item.reason}
                      </MenuItem>
                    ))}
                  </Select>
                </GridItem>
                {autoUnblockMonths > 0 && (
                  <GridItem>
                    <b>سيتم الغاء الحظر تلقائيا بعد ({autoUnblockMonths}) شهر</b>
                  </GridItem>
                )}

                <div className={classes.mSection}>
                  <GridItem xs={12} sm={12} md={12}>
                    <label className={classes.mLabel}>معلومات اضافية</label>
                    <TextField
                      multiline
                      rows={4}
                      rowsMax={8}
                      variant="outlined"
                      className={classes.note}
                      value={blockNote}
                      onChange={this.handleBlockNoteChange}
                    />
                  </GridItem>
                </div>
                <div className={classes.mSection}>
                  <GridItem xs={12} sm={12} md={12}>
                    <label htmlFor="newReason" className={classes.mLabel}>
                      حالة الحظر
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span dir="ltr">
                        <Switch
                          onChange={this.handleBlockStateChange}
                          checked={isUserBlocked}
                          onColor="#f44336"
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                      </span>
                      {isUserBlocked ? (
                        <span style={{ marginRight: 10, color: '#f44336', fontSize: 12 }}>
                          محظور
                        </span>
                      ) : (
                        <span style={{ marginRight: 10, color: '#888888', fontSize: 12 }}>
                          غير محظور
                        </span>
                      )}
                    </div>
                  </GridItem>
                </div>
              </div>
              <div className={`${classes.mFooter}`}>
                <Button
                  color="danger"
                  outline
                  onClick={this.handleEditClose}
                  style={{ marginTop: 10 }}
                >
                  إلغاء
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: 10 }}
                  onClick={this.handleUpdateBlockedUser}
                  disabled={!this.state.blockReason.id}
                >
                  إرسال
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className={classes.modal}
          open={this.state.isCreateOpen}
          onClose={this.handleCreateClose}
        >
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.mTitle}>إضافة حظر لمستخدم </p>
            </div>
            <div className={classes.mSection}>
              <GridItem xs={12} sm={12} md={12}>
                <label htmlFor="updateReason" className={classes.mLabel}>
                  رقم الأحوال/الإقامة
                </label>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <input
                  id="UserId"
                  className={classes.mSubInput}
                  variant="outlined"
                  value={idUser}
                  onChange={this.handleBlockIdChange}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
              </GridItem>
            </div>
            <div>
              <div className={classes.mSection}>
                <GridItem xs={12} sm={12} md={12}>
                  <label htmlFor="Reason" className={classes.mLabel}>
                    سبب الحظر
                  </label>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Select
                    onChange={this.handleBlockReasonChange}
                    value={blockReason.id}
                    input={<OutlinedInput labelWidth={0} name="type" />}
                    className={classes.mSelect}
                  >
                    {blockReasons.map(item => (
                      <MenuItem id={item.id} value={item.id}>
                        {item.reason}
                      </MenuItem>
                    ))}
                  </Select>
                </GridItem>
                {autoUnblockMonths > 0 && (
                  <GridItem>
                    <b>سيتم الغاء الحظر تلقائيا بعد ({autoUnblockMonths}) شهر</b>
                  </GridItem>
                )}

                <div className={classes.mSection}>
                  <GridItem xs={12} sm={12} md={12}>
                    <label className={classes.mLabel}>معلومات اضافية</label>
                    <TextField
                      label="معلومات إضافية"
                      multiline
                      rows={2}
                      rowsMax={8}
                      variant="outlined"
                      className={classes.note}
                      value={blockNote}
                      onChange={this.handleBlockNoteChange}
                    />
                  </GridItem>
                </div>
              </div>
            </div>
            <div className={`${classes.mFooter}`}>
              <Button
                color="danger"
                outline
                onClick={this.handleCreateClose}
                style={{ marginTop: 10 }}
              >
                إلغاء
              </Button>
              <Button
                color="primary"
                style={{ marginTop: 10 }}
                onClick={this.handleCreateBlockedUser}
                disabled={!this.state.blockReason.id}
              >
                إرسال
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          className={`${classes.modal} ${classes.historyModal}`}
          open={this.state.isHistoryOpen}
          onClose={this.handleHistoryClose}
        >
          <div className={classes.mContainer}>
            <div className={classes.mHeader}>
              <p className={classes.mTitle}>تاريخ الحظر</p>
            </div>

            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    الحدث
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    تاريخ الحدث
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold' }} className={classes.cell}>
                    بواسطة
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {userHistory.map(his => (
                  <TableRow>
                    <TableCell className={classes.cell}>
                      {BLOCK_HISTORY_ACTIONS[his.event]}
                    </TableCell>
                    <TableCell className={classes.lgCell}>{his.date.substring(0, 10)}</TableCell>
                    <TableCell className={classes.cell}>{his.user}</TableCell>
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {modalLoading ? (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <CircularProgress />
              </div>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  }
}

BlockList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }),
};
export default compose(
  withStyles(BlockListStyle),
  connect(),
  withRouter,
)(BlockList);
