import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
// core components
import GridItem from 'components/core/Grid/GridItem';
import Button from 'components/core/CustomButtons/Button';
import Card from 'components/core/Card/Card';
import CardHeader from 'components/core/Card/CardHeader';
import CardBody from 'components/core/Card/CardBody';
import UsersStyle from 'assets/jss/views/UserProfileStyle';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Email from '@material-ui/icons/Email';
import Person from '@material-ui/icons/Person';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Name from '@material-ui/icons/VerifiedUser';
import Phone from '@material-ui/icons/Phone';
import CardId from '@material-ui/icons/CreditCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import PermissionForm from './PermissionForm';
import { request, URLs } from '../../request';
import { getToken } from '../../utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class EditUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      username: '',
      open: false,
      role: 0,
      permissions: [],
      data: [],
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handleIdChange = this.handleIdChange.bind(this);
    this.handleUpdateUserSubmit = this.handleUpdateUserSubmit.bind(this);
    this.handleClose = () => {
      this.setState({ open: false });
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    request.GET(URLs.backoffice.USERS, { user_id: id }).then(respond => {
      respond.data.granted.map(el => this.setState({ [el]: true }));
      this.setState({
        data: [respond.data],
        email: respond.data.email,
        name: respond.data.name,
        username: respond.data.username,
        mobile: respond.data.mobile,
        id_number: respond.data.id_number,
        permissions: respond.data.granted,
        role: respond.data.role,
        loading: false,
      });
    });
  }

  handleChange = name => event => {
    const checked = event.target.checked;
    /* eslint-disable-next-line */
    let permissions = [...this.state.permissions];
    if (checked) {
      permissions.push(name);
    } else {
      permissions = permissions.filter(p => p !== name);
    }
    this.setState({ [name]: checked, permissions });
  };

  handleUserRoleChange = e => {
    const value = parseInt(e.target.value);
    if (e.target.checked) {
      this.setState({ role: value });
    } else {
      this.setState({ role: 0 });
    }
  };

  handleEmailChange(evt) {
    this.setState({ email: evt.target.value });
  }

  handleUsernameChange(evt) {
    this.setState({ username: evt.target.value });
  }

  handleNameChange(evt) {
    this.setState({ name: evt.target.value });
  }

  handleMobileChange(evt) {
    this.setState({ mobile: evt.target.value });
  }

  handleIdChange(evt) {
    this.setState({ id_number: evt.target.value });
  }

  handleUpdateUserSubmit() {
    const email = this.state.email;
    const username = this.state.username;
    const name = this.state.name;
    const mobile = this.state.mobile;
    const id = this.state.id_number;
    const url = process.env.REACT_APP_API_URL;
    const permissions = this.state.permissions;
    const role = this.state.role;
    const token = getToken();

    const bodyParameters = {
      name,
      email,
      username,
      mobile,
      id_number: id,
      permissions,
      role,
    };

    axios
      .put(`${url}/v1/users/${this.state.data[0].id}`, bodyParameters, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        this.setState({ open: true });
        this.props.history.push('/users');
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Grid container>
          {this.state.loading ? (
            <CircularProgress className={classes.progress} />
          ) : (
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>تعديل مستخدم</h4>
                  <p className={classes.cardCategoryWhite}>
                    بإمكانك هنا تعديل بيانات المستخدمين في النظام
                  </p>
                </CardHeader>
                <CardBody>
                  <Grid container justify="center">
                    <GridItem xs={6} sm={6} md={6}>
                      <TextField
                        label="اسم المستخدم"
                        id="username"
                        type="text"
                        value={this.state.username}
                        fullWidth
                        margin="normal"
                        onChange={this.handleUsernameChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Name />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <TextField
                        label="البريد الإلكتروني"
                        id="email"
                        type="email"
                        value={this.state.email}
                        fullWidth
                        margin="normal"
                        onChange={this.handleEmailChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <TextField
                        label="الإسم"
                        id="name"
                        type="text"
                        value={this.state.name}
                        fullWidth
                        margin="normal"
                        onChange={this.handleNameChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Person />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <TextField
                        label="رقم الهاتف"
                        id="mobile"
                        type="number"
                        value={this.state.mobile}
                        fullWidth
                        margin="normal"
                        onChange={this.handleMobileChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Phone />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Grid>
                    <GridItem xs={6} sm={6} md={6}>
                      <TextField
                        disabled
                        label="رقم الأحوال"
                        id="id_number"
                        type="number"
                        value={this.state.id_number}
                        fullWidth
                        margin="normal"
                        onChange={this.handleIdChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CardId />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <br />
                  <Grid container>
                    <div>
                      {this.state.data[0].id !== 1 ? (
                        <div>
                          <GridItem xs={12} sm={12} md={12} style={{ margin: '0 0 20px 0' }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="HRSD_VP"
                                  value={1}
                                  checked={this.state.role === 1}
                                  onChange={this.handleUserRoleChange}
                                />
                              }
                              label="ممثل وزارة الموارد البشرية"
                            />
                          </GridItem>
                          {this.state.role === 0 && (
                            <PermissionForm
                              permissions={this.state.permissions}
                              onChange={this.handleChange}
                            />
                          )}
                        </div>
                      ) : (
                        <div>
                          <p>جميع المهام</p>
                        </div>
                      )}
                    </div>

                    <GridItem xs={12}>
                      <Button color="info" onClick={this.handleUpdateUserSubmit}>
                        حفظ
                      </Button>
                    </GridItem>
                  </Grid>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    ContentProps={{
                      'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">تم تعديل بيانات المستخدم</span>}
                    action={[
                      <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleClose}
                      >
                        <CloseIcon />
                      </IconButton>,
                    ]}
                  />
                </CardBody>
              </Card>
            </GridItem>
          )}
        </Grid>
      </div>
    );
  }
}

EditUsers.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    isRoot: state.auth.isRoot,
  };
};
export default compose(
  withStyles(UsersStyle),
  connect(mapStateToProps),
  withRouter,
)(EditUsers);
